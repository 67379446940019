<template>
  <div class="reset-password-wrapper">
    <GeometricBackground />
    <router-link :to="{ name: 'forgot-password' }" tag="button" class="back-arrow" tabindex="0">
      <em class="material-icons">arrow_back</em>
    </router-link>
    <div class="reset-password-container">
      <div class="container-elements">
        <img class="reset-password-check-email-logo-mobile" alt="Company logo." />
        <h1 class="h1-primary">Password reset email sent. Please check your email.</h1>
        <div>
          <p>We have sent a password reset email to {{ $route.params.email || `email@domain.com` }} with instructions on how to reset your password.</p>
          <p>
            If you do not receive an email, please check if the email address you entered is correct, check our FAQs or get in touch with support
            <a href="https://swoopfunding.com/contact-us/" target="_blank" rel="noopener" tabindex="1" class="contact-link">here</a>.
          </p>
        </div>
        <div class="reset-password-buttons">
          <router-link :to="{ name: 'login' }" tag="button" tabindex="2">Sign in</router-link>
          <router-link :to="{ name: 'sign-up-form-type' }" tag="button" tabindex="3">Create account</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentSizeRoutine } from '@/store/modules/geometricBackground/routines'
import GeometricBackground from '@/components/GeometricBackground.vue'
import store from '@/store'

export default {
  name: 'resetPasswordCheckEmail',
  components: { GeometricBackground },
  beforeMount() {
    // Setting correct geometric background size for this route.
    store.dispatch(componentSizeRoutine.TRIGGER, 'full-width')
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.reset-password-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .back-arrow {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 22px;
    color: $color-white;
    background: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  .reset-password-container {
    height: 561px;
    max-height: 100vh;
    width: 500px;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    background-color: $color-white;
    box-sizing: content-box;
    border: 1px solid var(--color-secondary-50);
    border-radius: 8px;
    overflow: auto;

    .container-elements {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto 0;

      .reset-password-check-email-logo-mobile {
        content: url('../../assets/images/config/logo-mobile.svg');
      }

      img {
        width: 170px;
        margin-bottom: 39px;
      }

      h1 {
        width: 368px;
        font-size: 24px;
        letter-spacing: -0.34px;
        text-align: center;
        line-height: 28px;
        margin-bottom: 28px;
      }

      p {
        width: 384px;
        font-size: 18px;
        letter-spacing: -0.4px;
        text-align: center;
        line-height: 24px;
        color: var(--color-primary-400);
      }

      .contact-link {
        color: $color-primary;
      }

      .reset-password-buttons {
        width: 343px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 30px;

        button {
          height: 32px;
          width: 160px;
          border: 1px solid var(--color-primary-200);
          border-radius: 16px;
          background-color: transparent;
          color: var(--color-primary-400);
          letter-spacing: -0.4px;
          line-height: 14px;
          text-align: center;
        }

        button:hover {
          cursor: pointer;
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-md) {
    .back-arrow {
      display: none;
    }

    .reset-password-container {
      height: 100vh;
      width: 100vw;
      overflow: hidden auto;
      border-radius: 0px;

      .container-elements {
        img {
          margin-bottom: 24px;
        }

        h1 {
          width: 267px;
          font-size: 24px;
        }

        p {
          width: 266px;
          font-size: 16px;
        }

        .reset-password-buttons {
          width: 247px;
          flex-direction: column;
          margin-top: 32px;

          button {
            margin-bottom: 16px;
            width: 247px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-xs) {
    .reset-password-container {
      .container-elements {
        img {
          width: 120px;
          margin-bottom: 18px;
        }

        h1 {
          width: 215px;
          font-size: 18px;
        }

        p {
          width: 202px;
          font-size: 12px;
        }

        .reset-password-buttons {
          width: 200px;
          flex-direction: column;
          margin-top: 16px;

          button {
            margin-bottom: 16px;
            width: 200px;
          }
        }
      }
    }
  }
}
</style>
